import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getVideoSectionAppSettingsPath, playbackSpeedValuesToProGalleryMap, playValuesToProGalleryMap, VIDEO_SETTINGS, VIDEO_SETTINGS_NAMES, } from '../../constants';
var PLAY = VIDEO_SETTINGS_NAMES.PLAY, PLAYBACK_SPEED = VIDEO_SETTINGS_NAMES.PLAYBACK_SPEED, SOUND = VIDEO_SETTINGS_NAMES.SOUND, LOOP = VIDEO_SETTINGS_NAMES.LOOP, SHOW_PLAY_BUTTON = VIDEO_SETTINGS_NAMES.SHOW_PLAY_BUTTON;
var getPlay = function (state, section) {
    var playIndex = get(state.appSettings, getVideoSectionAppSettingsPath(section, PLAY), VIDEO_SETTINGS[PLAY].defaultValue);
    return playValuesToProGalleryMap[playIndex];
};
var getPlaybackSpeed = function (state, section) {
    var playbackSpeedIndex = get(state.appSettings, getVideoSectionAppSettingsPath(section, PLAYBACK_SPEED), VIDEO_SETTINGS[PLAYBACK_SPEED].defaultValue);
    return playbackSpeedValuesToProGalleryMap[playbackSpeedIndex];
};
var getSound = function (state, section) {
    return get(state.appSettings, getVideoSectionAppSettingsPath(section, SOUND), VIDEO_SETTINGS[SOUND].defaultValue);
};
var getLoop = function (state, section) {
    return get(state.appSettings, getVideoSectionAppSettingsPath(section, LOOP), VIDEO_SETTINGS[LOOP].defaultValue);
};
var getShowPlayButton = function (state, section) {
    return get(state.appSettings, getVideoSectionAppSettingsPath(section, SHOW_PLAY_BUTTON), VIDEO_SETTINGS[SHOW_PLAY_BUTTON].defaultValue);
};
export var getVideoSettingsWithProGalleryValues = createSelector([getPlay, getPlaybackSpeed, getSound, getLoop, getShowPlayButton], function (play, playbackSpeed, sound, loop, showPlayButton) { return ({
    play: play,
    playbackSpeed: playbackSpeed,
    sound: sound,
    loop: loop,
    showPlayButton: showPlayButton,
}); });
