var _a;
import { SECTION_POST_LIST, SECTION_RELATED_POSTS, FEED_PAGE_SECTIONS, } from './sections';
export var VIDEO_SETTINGS_NAMES = {
    PLAY: 'play',
    SOUND: 'sound',
    PLAYBACK_SPEED: 'playbackSpeed',
    LOOP: 'loop',
    SHOW_PLAY_BUTTON: 'showPlayButton',
};
export var playValuesToProGalleryMap = ['hover', 'auto', 'onClick'];
export var playbackSpeedValuesToProGalleryMap = [
    '0.25',
    '0.5',
    '1',
    '1.25',
    '1.50',
    '2',
];
export var VIDEO_SETTINGS = (_a = {},
    _a[VIDEO_SETTINGS_NAMES.PLAY] = {
        defaultValue: 0,
        param: 'play',
        appSettingsPath: 'style.numbers.postListWidgetPostsPerPage',
        values: {
            ON_HOVER: 0,
            AUTOPLAY: 1,
            ON_CLICK: 2,
        },
    },
    _a[VIDEO_SETTINGS_NAMES.SOUND] = {
        defaultValue: false,
        param: 'sound',
    },
    _a[VIDEO_SETTINGS_NAMES.PLAYBACK_SPEED] = {
        defaultValue: 2,
        param: 'playbackSpeed',
        values: {
            '0.25x': 0,
            '0.5x': 1,
            '1x': 2,
            '1.25x': 3,
            '1.5x': 4,
            '2x': 5,
        },
    },
    _a[VIDEO_SETTINGS_NAMES.LOOP] = {
        defaultValue: true,
        param: 'loop',
    },
    _a[VIDEO_SETTINGS_NAMES.SHOW_PLAY_BUTTON] = {
        defaultValue: true,
        param: 'showPlayButton',
    },
    _a);
var getSection = function (section) {
    if (section === SECTION_POST_LIST) {
        return SECTION_POST_LIST;
    }
    if (section === SECTION_RELATED_POSTS) {
        return SECTION_RELATED_POSTS;
    }
    if (FEED_PAGE_SECTIONS.includes(section)) {
        return 'feed-page';
    }
};
export var getVideoSectionWixparam = function (section, param) {
    return "".concat(getSection(section), "-video-").concat(VIDEO_SETTINGS[param].param);
};
export var getVideoSectionAppSettingsPath = function (section, param) {
    if (typeof VIDEO_SETTINGS[param].defaultValue === 'number') {
        return "style.numbers.".concat(getVideoSectionWixparam(section, param));
    }
    return "style.booleans.".concat(getVideoSectionWixparam(section, param));
};
