import { ThunkAction, ThunkActionDispatch } from 'redux-thunk';
import { resolvePromisifiedAction } from './store/promisified-actions-actions';

type DataSelectorFn<T> = (result: any) => T;
type ErrorSelectorFn<T> = (err: any) => T;

export function createPromisifiedAction<
  ActionCreatorFn extends (...args: any[]) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector: ErrorSelectorFn<T> = (e: any) => e,
): (
  args: Parameters<ActionCreatorFn>[0],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T> {
  return (args: Parameters<ActionCreatorFn>[0], correlationId?: any) =>
    (dispatch: ThunkActionDispatch<any>) => {
      return dispatch(action(args[0])).then(
        (result: any) => {
          return dispatch(
            resolvePromisifiedAction(true, correlationId, dataSelector(result)),
          );
        },
        (err: any) => {
          return dispatch(
            resolvePromisifiedAction(false, correlationId, errorSelector(err)),
          );
        },
      );
    };
}
